@import url('./fonts.css');

:root {
  --accent-color: #007bff;
  --accent-color-hover: #0056b3;
  --text-accent-color: #fff;

  --button-background-color: var(--accent-color);
  --button-text-color: var(--text-accent-color);
  --button-background-hover-color: color-mix(in hsl, var(--button-background-color) 90%, white 10%);
  --button-border-radius: 5px;
  --button-large-padding: 18px 24px;
  --button-large-font-size: 20px;
  --button-small-font-size: 16px;
  --button-small-padding: 14px 16px;
  --button-arrow-display: block;

  --input-border-color: #ccc;

  --footer-partner-section-border-color: #f5f5f5;

  --toast-success-left-color: #6cb214;
  --toast-success-font-color: #619c01;
  --toast-success-background-color: #ffffff;
  --toast-error-left-color: #d64040;
  --toast-error-font-color: #b82525;
  --toast-error-background-color: #f9eded;
  --toast-info-left-color: #1434cb;
  --toast-info-font-color: #132ca0;
  --toast-info-background-color: #eaf1fc;

  --text-body-color: #000;

  --hero-font-color: #000;
  --hero-right-color: #000;

  --quote-section-color: #fff;

  --modal-overlay-color: rgba(0, 0, 0, 0.8);
  --modal-background-color: white;
  --modal-border-radius: 24px;

  --container-padding-mobile: 16px;
  --container-padding-desktop: 80px;

  scroll-behavior: smooth;
  scroll-padding-top: 48px;

  --text-header-color: #6e6e6e;

  --background-color: #fff;
  --separator-color: #eaeaea;
  --shadow-color: rgba(0, 0, 0, 0.04);

  --selected-color: #fff6db;

  --section-background-color: #fbf6e9;
  --header-divider-color: #f5f5f5;

  --select-background-color: #fff;
  --select-label-color: #2f2f2f;
  --select-item-background-color-hover: #fff6db;
  --select-item-background-color-selected: #f6b700;
  --select-item-color-selected: #fff;
}

#cc-main .cm__btn[data-role='all'] {
  --cc-btn-primary-bg: var(--accent-color);
  --cc-btn-primary-hover-bg: var(--button-background-hover-color);
  --cc-btn-primary-border-color: var(--input-border-color);
  --cc-btn-primary-hover-border-color: var(--button-background-hover-color);
  --cc-btn-primary-color: var(--button-text-color);
  --cc-font-family: inherit;
}

#cc-main .pm__section-desc {
  white-space: pre-line;
}

#cc-main .cookie-description__list-element {
  list-style: circle;
  display: list-item;
  margin-left: 16px;
}

/* typography */
.heading1 {
  font-size: 32px;
  line-height: 35px;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 58px;
  }
}

.heading4 {
  font-size: 24px;
  line-height: 28px;
}

.bodyLarge {
  font-size: 16px;
  line-height: 24px;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
}

/* reusable styles */
.container {
  padding-right: var(--container-padding-mobile);
  padding-left: var(--container-padding-mobile);

  @media (min-width: 768px) {
    padding-right: var(--container-padding-desktop);
    padding-left: var(--container-padding-desktop);
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
}

.section {
  padding-top: 64px;
  padding-bottom: 64px;

  @media (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
